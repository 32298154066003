<template>
  <div class="card p-3">
    <h6>Assigned tribes</h6>
    <div class="d-flex flex-wrap">
      <div
        v-for="tribe in assignedTribe"
        :key="tribe._id"
        class="tribes-assigned-vendor"
      >
        <img
          :src="tribe.cover_image"
          class="rounded mb-2"
          alt="Cover image"
          style="width: 100%"
        /><br />
        <strong>{{ tribe.name }}</strong>
        {{ assignedTribe.title }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["assignedTribe"],
};
</script>

<style>
.tribes-assigned-vendor {
  width: 180px;
  margin: 8px;
}
</style>
