<template>
  <div class="card p-3" v-if="vendorDetails">
    <h6>Vendor Details</h6>
    <div class="row">
      <div class="col-4">
        <strong>Email</strong>
      </div>
      <div class="col-8">
        {{ vendorDetails.email }}
      </div>
    </div>
    <div class="row">
      <div class="col-4">
        <strong>Company Name</strong>
      </div>
      <div class="col-8">
        {{ vendorDetails.company_name }}
      </div>
    </div>
    <div class="row">
      <div class="col-4">
        <strong>Contact No.</strong>
      </div>
      <div class="col-8">
        {{ vendorDetails.contact_number }}
      </div>
    </div>
    <div class="row">
      <div class="col-4">
        <strong>Local Address</strong>
      </div>
      <div class="col-8">
        {{ vendorDetails.local_address }}
      </div>
    </div>
    <div class="row">
      <div class="col-4">
        <strong>City</strong>
      </div>
      <div class="col-8">
        {{ vendorDetails.city }}
      </div>
    </div>
    <div class="row">
      <div class="col-4">
        <strong>Contact Name</strong>
      </div>
      <div class="col-8">
        {{ vendorDetails.contact_name }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["vendorDetails"],
};
</script>

<style></style>
