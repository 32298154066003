<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import AssignedTribe from "@/components/vendor/assigned-tribe";
import { tribe } from "@/config/api/vendor/tribes.js";
import BankDetails from "@/components/vendor/bank-details";
import VendorDetails from "@/components/vendor/vendor-details";

export default {
  components: {
    Layout,
    PageHeader,
    AssignedTribe,
    BankDetails,
    VendorDetails,
  },
  data() {
    return {
      title: "Dashboard",
      items: [
        {
          text: "Vendor",
        },
        {
          text: "Dashboard",
          active: true,
        },
      ],
      assignedTribe: null,
      bankDetails: null,
      vendorDetails: null,
      loadAssignedTribe: false,
    };
  },
  created() {
    this.loadAssignedVendorData();
  },
  methods: {
    loadAssignedVendorData() {
      const api = tribe.getAssignedTribeInfo;
      this.loadAssignedTribe = true;
      this.generateAPI(api)
        .then((res) => {
          this.assignedTribe = res.data.vendor.assigned_tribe;
          this.bankDetails = res.data.vendor.bank_details;
          this.vendorDetails = res.data.vendor;
          delete this.vendorDetails["assigned_tribe"];
          delete this.vendorDetails["bank_details"];
        })
        .catch((err) => {
          console.log(err.data);
        })
        .finally(() => {
          this.loadAssignedTribe = false;
          console.log("Fetching completed!");
        });
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <b-skeleton-wrapper :loading="loadAssignedTribe">
          <template #loading>
            <b-card>
              <b-skeleton width="30%"></b-skeleton>
              <div class="d-flex flex-wrap">
                <div class="mb-2" style="width: 20%">
                  <b-skeleton width="70%"></b-skeleton>
                  <b-skeleton width="70%"></b-skeleton>
                  <b-skeleton width="70%"></b-skeleton>
                  <b-skeleton width="70%"></b-skeleton>
                  <b-skeleton width="70%"></b-skeleton>
                </div>
                <div style="width: 80%">
                  <b-skeleton width="80%"></b-skeleton>
                  <b-skeleton width="80%"></b-skeleton>
                  <b-skeleton width="80%"></b-skeleton>
                  <b-skeleton width="80%"></b-skeleton>
                  <b-skeleton width="80%"></b-skeleton>
                </div>
              </div>
            </b-card>
            <b-card>
              <b-skeleton width="30%"></b-skeleton>
              <div class="d-flex flex-wrap">
                <div style="width: 180px; margin: 8px">
                  <div class="mb-2">
                    <b-skeleton-img></b-skeleton-img>
                  </div>
                  <div>
                    <b-skeleton width="70%"></b-skeleton>
                    <b-skeleton width="100%"></b-skeleton>
                  </div>
                </div>
                <div style="width: 180px; margin: 8px">
                  <div class="mb-2">
                    <b-skeleton-img></b-skeleton-img>
                  </div>
                  <div>
                    <b-skeleton width="70%"></b-skeleton>
                    <b-skeleton width="100%"></b-skeleton>
                  </div>
                </div>
                <div style="width: 180px; margin: 8px">
                  <div class="mb-2">
                    <b-skeleton-img></b-skeleton-img>
                  </div>
                  <div>
                    <b-skeleton width="70%"></b-skeleton>
                    <b-skeleton width="100%"></b-skeleton>
                  </div>
                </div>
              </div>
            </b-card>
            <b-card>
              <b-skeleton width="30%"></b-skeleton>
              <div class="d-flex flex-wrap">
                <div class="mb-2" style="width: 20%">
                  <b-skeleton width="70%"></b-skeleton>
                  <b-skeleton width="70%"></b-skeleton>
                  <b-skeleton width="70%"></b-skeleton>
                  <b-skeleton width="70%"></b-skeleton>
                </div>
                <div style="width: 80%">
                  <b-skeleton width="100%"></b-skeleton>
                  <b-skeleton width="100%"></b-skeleton>
                  <b-skeleton width="100%"></b-skeleton>
                  <b-skeleton width="100%"></b-skeleton>
                </div>
              </div>
            </b-card>
          </template>
          <VendorDetails :vendorDetails="vendorDetails" />
          <AssignedTribe :assignedTribe="assignedTribe" />
          <BankDetails :bankDetails="bankDetails" />
        </b-skeleton-wrapper>
      </div>
    </div>
  </Layout>
</template>
